<template>
  <div>
    <x-table
      v-bind="tableScheme"
      :items="taskItems"
      class="mt-3 taskList freeze-column-3"
    />
    <pagination
      ref="pager"
      :query="query"
      :fetch="$Task.fetchTaskList"
      :callback="pagerCallback"
    />
  </div>
</template>

<script>
import { formatDatetimeFromISOString, toDateObj } from '@/utils/date'
import { numberWithCommas, normalizeCount } from '@/utils/number'
import { authenticate } from '@/auth/allowAuthMapping.js'
import taskActionMixin, * as taskAction from './TaskActionMixin'
import Pagination from '@/components/Pagination'

const ACTION_RE_TRIGGER = 'ReTrigger'
const LINK_TO_SCHEDULE = 'TaskSchedule'
const LINK_TO_CAMPAIGN_DETAIL = 'CampaignDetail'

export default {
  name: 'TaskListTable',
  components: { Pagination },
  mixins: [taskActionMixin],
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    showCampaign: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const tableHeader = composeTableHeader.call(this, this.showCampaign)
    const {
      editButton,
      viewButton,
      submitButton,
      terminateButton,
      deleteButton
    } = taskAction.getButtons(this)
    return {
      rawTaskItems: [],
      tableScheme: {
        multiSelectable: false,
        headers: tableHeader,
        actions: [
          editButton,
          viewButton,
          submitButton,
          terminateButton,
          deleteButton
        ],
        clickCallback: (item, e) => {
          switch (e.target.dataset.linkTo) {
            case LINK_TO_CAMPAIGN_DETAIL:
              return this.showCampaign
                ? this.$router.push({
                  name: 'CampaignDetail',
                  params: { campaignId: item.campaignId }
                })
                : null
            case LINK_TO_SCHEDULE:
              return this.$router.push({
                name: 'TaskSchedule',
                params: { taskId: item.id }
              })
            case ACTION_RE_TRIGGER:
              return this.retriggerIntent(item, e)
            default:
              return false
          }
        }
      }
    }
  },
  computed: {
    taskItems () {
      return this.rawTaskItems.map((task, ind) => {
        task.campaign = {}
        return {
          no: this.$refs.pager.offset + ind + 1,
          ...task,
          channel: this.$t(`Task.channel.${task.channel}`),
          bannedCount: normalizeCount(task.bannedCount),
          restrictedCount: normalizeCount(task.restrictedCount),
          statusDisplay: this.$t(`Task.status_list['${task.status}']`),
          executeStatusDisplay: this.$t(
            `Task.execute_status_list['${task.executeStatus}']`
          ),
          startTimeFormatted: formatDatetimeFromISOString(task.startTime),
          endTimeFormatted: formatDatetimeFromISOString(task.endTime),
          createTimeFormatted: formatDatetimeFromISOString(task.createTime),
          updateTimeFormatted:  formatDatetimeFromISOString(task.updateTime)
        }
      })
    }
  },
  async mounted () {
    await this.$refs.pager.fetchPage()
  },
  methods: {
    pagerCallback ({ tasks }) {
      this.rawTaskItems = tasks
    },
    refresh () {
      this.$refs.pager.refresh()
    },
    retriggerIntent (item, e) {
      this.$store.confirmDialog.push({
        message: 'The task will be re-triggered after you click Confirm',
        buttons: [
          {
            text: 'Cancel',
            outline: true,
            class: 'btnInfo',
            onClick: this.onCancel
          },
          {
            text: 'Confirm',
            class: 'btnPrimary',
            onClick: () => this.retrigger(item, e)
          }
        ]
      })
      return false
    },
    async retrigger ({ id }) {
      this.$store.loading = true
      try {
        await this.$Task.restartTask({ taskId: id })
        this.$store.successNotification = 'Task re-triggered successfully'
        await this.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    onCancel () {
      this.$store.confirmDialog.shift()
    }
  }
}

const addHours = (h, date = new Date()) => {
  date.setTime(date.getTime() + h * 60 * 60 * 1000)
  return date
}

const composeTableHeader = function (showCampaign) {
  const tableHeader = [
    { value: 'no', text: 'No' },
    { value: 'id', text: 'Task ID' },
    { value: 'name', text: 'Task Name' }
  ]
  if (showCampaign) {
    tableHeader.push(
      {
        value: 'campaignId',
        text: 'Campaign ID',
        render: item => {
          return `<span class="color-primary" data-link-to="${LINK_TO_CAMPAIGN_DETAIL}">${
            item.campaignId
          }</span>`
        }
      },
      {
        value: 'campaignName',
        text: 'Campaign Name'
      }
    )
  }
  tableHeader.push(
    { value: 'owner', text: 'Task Owner' },
    {
      value: 'segmentName',
      text: 'Segment Name',
      render: item => `(ID: ${item.segmentId}) ${item.segmentName}`
    },
    {
      value: 'groupName',
      text: 'Group Name',
      render: item => `(ID: ${item.groupId}) ${item.groupName}`
    },
    {
      value: 'userCount',
      text: '# of list',
      render: item => {
        if (item.count) return numberWithCommas(item.count)
        return `${numberWithCommas(item.userCount)} (${item.ratio}%)`
      }
    },
    {
      value: 'bannedCount',
      text: '# of Ban on Sales'
    },
    {
      value: 'restrictedCount',
      text: '# of Frequency Control'
    },
    { value: 'channel', text: 'Channel' },
    { value: 'statusDisplay', text: 'Status' },
    {
      value: 'executeStatus',
      text: 'Execute Status',
      render: item => {
        const current = toDateObj()
        const updateTime = toDateObj(item.updateTime)
        const retriggerTime = addHours(6, updateTime)
        const showRetrigger = current.getTime() > retriggerTime.getTime()
        const { executeStatus, executeStatusDisplay, hasJob } = item
        const customColor =
          {
            ready: 'dot-primary',
            executing: 'dot-orange',
            terminated: 'dot-bluey-grey',
            failed: 'dot-warning',
            successful: 'dot-secondary',
            notReady: 'dot-purple'
          }[executeStatus] || ''
        let html = `<span class="dot ${customColor}" >${executeStatusDisplay}</span>`
        if (hasJob) {
          html += `<button class="btn v-btn v-btn--outline" data-link-to="${LINK_TO_SCHEDULE}"><span>VIEW SCHEDULE</span></button>`
        }
        if (authenticate('TaskReTrigger') && executeStatus === 'failed') {
          html += `<button class="btn v-btn v-btn--outline" data-link-to="${ACTION_RE_TRIGGER}"><span>Re-trigger</span></button>`
        }
        if (authenticate('TaskReTrigger') && executeStatus === 'executing' && showRetrigger) {
          html += `<button class="btn v-btn v-btn--outline" data-link-to="${ACTION_RE_TRIGGER}"><span>Re-trigger</span></button>`
        }
        return html
      }
    },
    { value: 'startTimeFormatted', text: 'Start Date' },
    { value: 'endTimeFormatted', text: 'End Date' },
    { value: 'createTimeFormatted', text: this.$t('User.create_time') },
    { value: 'action', text: 'Actions' }
  )
  return tableHeader
}
</script>

<style lang="stylus" scoped>
$bg-color-even = #fff;
$bg-color-odd = #f7f9fa; // same as lazybee x-table
$bg-color-hover = #eff1f3; // same as lazybee x-table
$hover-text-color = rgba(72, 177, 254, 0.5); // same as lazybee #48b1fe, opacity 0.5
$offset = 1px;
$column-width1 = 68px;
$column-width2 = 192px;

.freeze-column-3 {
  >>> .v-table {
    border-collapse: unset;
    background-color: $bg-color-even;

    th:nth-child(1), th:nth-child(2), th:nth-child(3),
    td:nth-child(1), td:nth-child(2), td:nth-child(3) {
      background-color: $bg-color-even;
      position: sticky;
      z-index: 1; // workaround for opacity issue
    }

    th:nth-child(1), td:nth-child(1) {
      width: $column-width1;
      min-width: $column-width1;
      left: 0px;
    }

    th:nth-child(2), td:nth-child(2) {
      width: $column-width2;
      min-width: $column-width2;
      left: $column-width1;
    }

    th:nth-child(3), td:nth-child(3) {
      left: $column-width1 + $column-width2;
      box-shadow: inset -1px 0 #f0f0f0;
    }

    thead {
      tr th.sortable:hover {
        opacity: 1 !important;
        color: $hover-text-color !important;
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            background-color: $bg-color-hover !important;
          }
        }
        &:nth-child(4n + 1) {
          td {
            background-color: $bg-color-odd;
          }
        }
      }
    }
  }
}

.taskList {
 >>> .btn {
    font-size: $fs-mini;
    color: #666 !important;
    background: $white-imp;
    border-color: $light-grey;
    border-radius: 4px;
    box-shadow: none !important;
    height: 28px;
    line-height: @height;
    margin-left: 0;
    span {
      white-space: nowrap;
    }
    &:hover {
      background: $primary-imp;
      color: $white-imp;
    }

  }
}
</style>

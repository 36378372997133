import { authenticate } from '@/auth/allowAuthMapping.js'
import { greaterThanCurrentTime } from '@/utils/validation'
import { convertTemplateToHtml } from '@/components/SectionUI/const/sectionHtml'
import { STATUS, EXECUTE_STATUS, dataPlatform } from '@/const'
import { toDateObj } from '@/utils/date'

const mixin = {
  methods: {
    refresh () {
      this.$router.go()
    },
    async deleteTaskIntent (task, e) {
      e.stopPropagation()
      this.$store.confirmDialog.push({
        message: 'Are you sure to remove this task? This cannot be undone.',
        onConfirm: () => this.deleteTask(task.id)
      })
      return false
    },
    async deleteTask (taskId) {
      this.$store.loading = true
      try {
        await this.$Task.deleteTask({
          id: taskId
        })
        this.$store.successNotification = 'Task has been successfully removed'
        this.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    async submitTask (task, e) {
      this.$store.loading = true
      try {
        e.stopPropagation()
        greaterThanCurrentTime.call(
          this,
          task.startTime,
          dataPlatform.BUFFER_HOUR[this.$store.env]
        )
        const taskContent = await this.getTemplateContent(task)
        await this.$Task.updateTask({
          id: task.id,
          status: STATUS.approved,
          taskContent
        })
        this.$store.successNotification = 'Task has been successfully submitted'
        this.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
      return false
    },
    async terminateTask (task, e) {
      this.$store.loading = true
      try {
        e.stopPropagation()
        await this.$Task.pauseTask({
          taskId: task.id
        })
        this.$store.successNotification =
          'Task has been successfully terminated'
        this.refresh()
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
      return false
    },
    async getTemplateContent ({ templateId, channel }) {
      if (!templateId) return null
      // FIXME: This is a tmp workaround since backend is not able to get compiled html.
      const {
        data: { templates }
      } = await this.$Template.fetchTemplate({
        templateId
      })
      if (!templates.length) {
        throw new Error('Template not found. Please reselect template')
      }
      if (channel === 'SMS') {
        return templates[0].content
      }
      if (channel === 'EDM') {
        return convertTemplateToHtml(JSON.parse(templates[0].content))
      }
    }
  }
}

export default mixin

export const getButtons = context => {
  const defaultButtonScheme = {
    outline: true
  }
  return {
    editButton: {
      ...defaultButtonScheme,
      content: 'Edit',
      render: item =>
        authenticate('TaskEdit') &&
        item.executeStatus !== EXECUTE_STATUS.terminated &&
        (item.status === STATUS.draft || toDateObj(item.endTime) > toDateObj()),
      click: (item, e) => {
        e.stopPropagation()
        context.$router.push({
          name: 'TaskEdit',
          params: { taskId: item.id, viewOnly: false }
        })
        return false
      }
    },
    viewButton: {
      ...defaultButtonScheme,
      content: 'View',
      click: (item, e) => {
        e.stopPropagation()
        context.$router.push({
          name: 'TaskDetail',
          params: { taskId: item.id, viewOnly: true }
        })
        return false
      }
    },
    submitButton: {
      ...defaultButtonScheme,
      content: 'Submit',
      class: 'actionSubmit',
      render: item => authenticate('TaskEdit') && item.status === STATUS.draft,
      click: context.submitTask
    },
    terminateButton: {
      ...defaultButtonScheme,
      content: 'Terminate',
      class: 'actionWarning',
      render: item => {
        const current = toDateObj().getTime()
        const taskEndTime = toDateObj(item.endTime).getTime()
        return (
          (
            authenticate('TaskPause') &&
            item.status === STATUS.approved &&
            item.executeStatus !== EXECUTE_STATUS.terminated &&
            toDateObj(item.endTime) > toDateObj()
          ) ||
          (
            item.executeStatus !== EXECUTE_STATUS.terminated &&
            item.executeStatus !== EXECUTE_STATUS.successful &&
            current > taskEndTime
          )
        ) 
      },
      click: context.terminateTask
    },
    deleteButton: {
      ...defaultButtonScheme,
      content: 'Delete',
      class: 'actionWarning',
      render: item =>
        authenticate('TaskDelete') && item.status === STATUS.draft,
      click: context.deleteTaskIntent
    }
  }
}
